import "./testimonial.scss"

import testdp1 from "../../Assets/Image/AV1.png";
import testdp2 from "../../Assets/Image/AV2.webp";
import testdp3 from "../../Assets/Image/AV3.webp";

import { FaQuoteLeft } from "react-icons/fa";
import { Swiper, SwiperSlide } from "swiper/react";
// import Swiper core and required modules
import { Navigation, Pagination, FreeMode, A11y } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
const Testimonial = () => {


    const TestimonialCard = [
        {
          id: 1,
          img: testdp1,
          testimony:
            " I highly recommend Zenithcorp for anyone seeking reliable and authentic educational e-pins. Their user-friendly platform made it easy for me to purchase the necessary pins for my exams. The customer service was exceptional, ensuring a smooth and hassle-free experience.",
          name: "John",
        },
        {
          id: 2,
          img: testdp2,
          testimony:
            " Thanks to Zenithcorp's comprehensive consulting services, I gained valuable insights and strategies to excel in my NECO exams. The knowledgeable team provided personalized guidance, answering all my questions and helping me feel confident and well-prepared for the exams. It made a significant impact on my performance.",
          name: "Kunle",
        },
        {
          id: 3,
          img: testdp3,
          testimony:
            " I am incredibly grateful for Zenithcorp. Their expert guidance and study resources were instrumental in helping me achieve excellent results in my JAMB and WAEC exams. The platform's convenience, prompt service, and personalized support truly made a difference in my academic journey",
          name: "David",
        },
        {
          id: 4,
          img: testdp1,
          testimony:
            " I'm impressed with Zenithcorp's dedication to providing a holistic learning experience. Not only did they offer exam-related services, but their sales of airtime and data ensured I had uninterrupted access to online study materials. The platform's versatility and convenience make it a go-to resource for students like me seeking comprehensive support.",
          name: "emeka",
        },
      ];



    return (
        <section className="testiomonal-main">
        <div className="testiomonal-inner">
          <div className="testimonial-heading-main">
            <div className="testimonial-heading-inner">
              <span></span>
              <h3>Testimonials</h3>
              <span></span>
            </div>

            <h3 className="testimonial-heading1">What our clients say</h3>
          </div>

          <div className="test-card-section">
          <Swiper
          // install Swiper modules
          modules={[Navigation, Pagination, FreeMode, A11y]}
          breakpoints={{
            500: {
              slidesPerView: 2,
              spaceBetween: 50,
            },
           
          }}
          freeMode={true}
          spaceBetween={10}
          slidesPerView={1}
          pagination={{ clickable: true }}
          onSlideChange={() => console.log("slide change")}
          className="swiper"
        >

            {TestimonialCard.map((TestimonialCards, id) => (
              <SwiperSlide key={id} className="test-card-main">
                <div className="test-card-quotes">
                  
                <FaQuoteLeft />
                </div>
                <div class="feed-slide-title">
                  <span class="feed-slide-title1">
                    {TestimonialCards.testimony}
                  </span>
                  <span class="feed-dash-img">
                    <img
                      src={TestimonialCards.img}
                      alt=""
                      class="feed-slide-img"
                    />
                  </span>
                </div>

                <div class="feed-slide-subtitle">
                  <h1 class="feed-slid-subtitle-text">
                    {TestimonialCards.name}
                  </h1>
                </div>
                </SwiperSlide>
            ))}
             </Swiper>
          </div>
        </div>
      </section>
    )
}

export default Testimonial