import React, { useContext } from 'react';
import usertotal from "../../../Assets/Image/recharge.png";
import useractive from "../../../Assets/Image/money-transfer.png";
import userloan from "../../../Assets/Image/bill.png";
import usersaving from "../../../Assets/Image/cable-tv.png";
import result from "../../../Assets/Image/exam.png";
import consult from "../../../Assets/Image/consulting.png";
import webdev from "../../../Assets/Image/coding.png";
import gdesign from "../../../Assets/Image/logo-design.png";
import "./user.scss";
import WalletCard from "../../../components/Wallet/walletCard";
import { useState, useEffect } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import WalletContext from "../../../context/walletContext";
//import UserTable from '../../components/usersInfoTable/userTable';

const DashHome = () => {
  const { pocket, setPocket } = useContext(WalletContext);
  const axioprivate = useAxiosPrivate();
  const userName = localStorage.getItem("username");

  const POCKET_URL = `api/wallet/pocket/${userName}`;
  const [loading, setLoading] = useState(false);

  const [errMsg, setErrMsg] = useState("");
  

    
  //************************************** */
  const cardDetail = [
    { id: 1, title: "Airtime", image: usertotal, link: "dashboard/airtime" },
    { id: 2, title: "Data", image: useractive, link: "dashboard/data" },
    { id: 3, title: "Electricity", image: userloan, sup: "Coming Soon" },
    { id: 4, title: "Cable/TV", image: usersaving, sup: "Coming Soon" },
  ];

  const cardDetailContent = cardDetail.map((cardDetail) => (
    <div key={cardDetail.id} className="card-detail">
      <a href={cardDetail.link}>
        <sup>{cardDetail.sup}</sup>
        <img src={cardDetail.image} alt="icon" className="card-icon" />
        <h4>{cardDetail.title}</h4>
      </a>
    </div>
  ));
  //************************************** */

  //************************************** */
  const educationCard = [
    {
      id: 1,
      title: "Jamb",
      image: result,
      sup: "Coming Soon",
     
    },
    {
      id: 2,
      title: "Result Checker",
      sup: "Coming Soon",
      image: result,
     
    },
  ];

  const educationCardContent = educationCard.map((educationCard) => (
    <div key={educationCard.id} className="card-detail">
      <a href={cardDetail.link}>
        <sup>{educationCard.sup}</sup>
        <img src={educationCard.image} alt="icon" className="card-icon" />
        <h4>{educationCard.title}</h4>
      </a>
    </div>
  ));
  //************************************** */

  //************************************** */
  const othersDetail = [
    {
      id: 1,
      title: "Consultations",
      image: consult,
      link: "dashboard/consultation"
    },
    {
      id: 2,
      title: "Web Development",
      sup: "Coming Soon",
      image: webdev,
      
    },
    {
      id: 3,
      title: "Graphics Design",
      sup: "Coming Soon",
      image: gdesign,
     
    },
  ];


  

  const othersDetailContent = othersDetail.map((othersDetail) => (
    <div key={othersDetail.id} className="card-detail">
      <a href={othersDetail.link}>
        <sup>{othersDetail.sup}</sup>
        <img src={othersDetail.image} alt="icon" className="card-icon" />
        <h4>{othersDetail.title}</h4>
      </a>
    </div>
  ));
  //************************************** */

  const fetchPockets = async () => {
    setLoading(true);
    try {
      const response = await axioprivate.get(POCKET_URL, JSON.stringify({}));
      console.log(response?.data);
      const pocket = response?.data.pocket;
      
      localStorage.setItem('pocketState', pocket);
      setLoading(false);
      setPocket(pocket);
    } catch (err) {
      console.log(err);
      setErrMsg("Couldn't fetch balance");
    }
  };

  useEffect(() => {
    const storedPocketState = localStorage.getItem('pocketState');
  if (storedPocketState) {
    setPocket(storedPocketState);
  } else {
    fetchPockets(); // Fetch the pocket state if not found in local storage
  }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="user-home">
      <section className="home-wallet-card">
        <h4>Wallet</h4>
        <WalletCard  fetchPockets={fetchPockets} pocket={pocket}  errMsg={errMsg} loading={loading}/>
      </section>

      <section className="service-card">
        <h4>Bill Payments</h4>
        <div className="cards-main bill-card">{cardDetailContent}</div>
      </section>

      <section className="service-card">
        <h4>Educational Pins</h4>
        <div className="cards-main">{educationCardContent}</div>
      </section>

      <section className="service-card ">
        <h4>NYSC/Other Services</h4>
        <div className="cards-main other-sevice">{othersDetailContent}</div>
      </section>
    </div>
  );
};
export default DashHome;
