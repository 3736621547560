import React from 'react';
import DashBoardHeader from '../components/navigations/Dashboard/header/Dashboardheader';
import SideBar from '../components/navigations/Dashboard/side bar/sideBar';
import "./dashLayout.scss"


const DashboardLayout = ({ children }) => {
    return (
      <>
        <React.Fragment>
        <DashBoardHeader className="header"/>
        <SideBar className="sidebar"/>
        <main className="dashboard-main">{children}</main>
        </React.Fragment>
      </>
    )
  }
  
  export default DashboardLayout