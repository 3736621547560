import { useEffect, useState } from "react"
import "./fundAccount.scss"
import { useRef } from "react";
import { Spinner } from '@chakra-ui/react';
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";



const FUND_URL = 'api/wallet/transfer/'

const FundAccount = () => {
   

 
    const axioprivate = useAxiosPrivate()
    

    //const [userName, setUserName] = useState ("")


    const userNameDash = localStorage.getItem('username');


    const USER_URL = `api/auth/user/${userNameDash}`

    const [loading, setLoading] = useState(true);
    const [fundloading, setFundloading] = useState(false);

   // const userRef = useRef();
    const errRef = useRef();

    const [email, setEmail] = useState('')
    const [amount, setAmount] = useState('')
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(true);

   

    /****************Get User Email********************/
    const getEmail = async () => {
       
        try {
          const response = await axioprivate.get(USER_URL,
              JSON.stringify({ }),
              
          );
          console.log(response?.data);
          const usermail = response?.data.user.email;
         
          setEmail(usermail);
          setLoading(false);
          
      } catch (err) {
        console.log(err);
        setErrMsg("Couldn't fetch email");
      }
        };
    
    
      
      useEffect (() => {
       
        getEmail()
        console.log(userNameDash)
        console.log(email)
      }, )

    /****************Get User Email********************/



    /**************HANDLE SUBMIT**************/
const handleSubmit = async (e) => {
    e.preventDefault();
    setFundloading(true);
  
    try {
        const response = await axioprivate.post(FUND_URL,
            JSON.stringify({
                email,
                amount
              }),
              {
                headers: { 'Content-Type': 'application/json' },
                
                
            }
            
        );
        console.log(JSON.stringify(response?.data));
        //console.log(JSON.stringify(response));
        const payPage = response?.data
        
        
        setEmail('');
        setAmount('');
        setSuccess(true);
        console.log(success)
        window.location.replace(payPage);
       // navigate(from, { replace: true });
    } catch (err) {
        setFundloading(false);
        if (!err?.response) {
            setErrMsg('No Server Response');
        } else if (err.response?.status === 400) {
            setErrMsg('Missing mail or Amount');
        } else if (err.response?.status === 401) {
            setErrMsg('Unauthorized');
        } else {
            setErrMsg('Funding Failed');
        }
        errRef.current.focus();
      
    }
  }
  
  /**************HANDLE SUBMIT END**************/

    return (
        <section className='fund-main'>

        <div className='fund-inner'>

                <form>
                <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                   { /**************************/ }
                    <label>Email</label>

                    { loading=== true ?
                     <span> <Spinner className="btn-load" /></span> : 

                     <input  onChange={(e) => setAmount(e.target.value)}
                    value={email}
                     required
                     disabled
                     />
                     
                     }
                  
                   

                   { /************************** */}
                    <label>Amount</label>
                    <input placeholder='#100'
                    onChange={(e) => setAmount(e.target.value)}
                    value={amount}
                     required/>

                    <button className='fund-btn' onClick={handleSubmit}>
                    { fundloading=== false ? <span> Fund</span> : <span><Spinner className="btn-load" /> Processing..</span> }
                    </button>
                </form>
        </div>

       </section>
    )
}

export default FundAccount