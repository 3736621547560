import "./landing.scss";
import hero from "../../Assets/Image/educational.png";
import about from "../../Assets/Image/about.png";
import airtel from "../../Assets/Image/Airtel.jpg";
import eko from "../../Assets/Image/Eko.jpg";
import glo from "../../Assets/Image/GLO.jpg";
import mtn from "../../Assets/Image/MTN.jpg";
import dstv from "../../Assets/Image/Pay-DSTV.jpg";
import etisalat from "../../Assets/Image/9mobile.jpg";
import waec from "../../Assets/Image/WAEC.jpg";

import { Swiper, SwiperSlide } from "swiper/react";
// import Swiper core and required modules
import { Autoplay } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import { MdOutlineEast } from "react-icons/md";
import Testimonial from "../../components/testimonials/testimonial";
import { Link } from "react-router-dom";
import {
  FaPhoneVolume,
  FaMobileAlt,
  FaTv,
  FaLightbulb,
  FaJournalWhills,
} from "react-icons/fa";
import LandingHeader from "../../components/navigations/header/landindHeader";
import Footer from "../../components/navigations/footer/footer";

const Landing = () => {
  const CourseCard = [
    {
      id: 1,
      icon: <FaPhoneVolume />,
      head: "Airtime/ Data",
      descrip:
        "Quick and convenient access to mobile airtime and data bundles to keep you connected and online.",
      title: "Buy Airtime/ Data",
    },
    {
      id: 2,
      icon: <FaMobileAlt />,
      head: "Educational Consulting",
      descrip:
        "Professional advice and support for educational institutions, parents, and students to enhance educational practices and strategies.",
      title: "Reach Out",
    },
    {
      id: 3,
      icon: <FaTv />,
      head: "Cable TV",
      descrip:
        " Hassle-free subscriptions for electricity and cable TV services (GoTV and DSTV) to meet your entertainment and connectivity needs.",
      title: "Recharge Cable Tv",
    },
    {
      id: 4,
      icon: <FaLightbulb />,
      head: "Electricity",
      descrip:
        "Pay your light bills hassle free and get your token immediately( AEDC, EKEDC, IBEDC, IKEDC, JED, KAEDCO, KEDCO & PHED)",
      title: "Pay electricity",
    },
    {
      id: 5,
      icon: <FaJournalWhills />,
      head: "E-pins",
      descrip: "Convenient access to registration and result checking pins for Jamb, WAEC, NECO, and other examinations.",
      title: "Buy E-pin",
    },
  ];

  const ServiceImage = [
    { img: airtel },
    { img: eko },
    { img: glo },
    { img: mtn },
    { img: dstv },
    { img: etisalat },
    { img: waec },
  ];

  return (
    <>
    <LandingHeader />
      <div className="home-main">
        {/*********************************LANDING SECTION*******************/}

        <div className="hero-main">
          <section className="hero-section">
            <div className="hero-image">
              <span>
                <img src={hero} alt="hero" />
              </span>
            </div>

            <div className="landing-desc">
              <div className="sub1">Making the journey easy</div>

              <div className="sub2">
                We are a leading provider of educational solutions,
                <br />
                <span>
                  {" "}
                  dedicated to helping students achieve academic excellence and
                  reach their full potential.
                </span>
              </div>

              <div className="sub3">
                <a href="#Services">
                  <button>Services</button>
                </a>

                <Link to="/signup">
                  <button>Sign Up</button>
                </Link>
              </div>
            </div>
          </section>
        </div>
        {/*********************************LANDING SECTION END*******************/}

        {/**********************************FEATURES SECTION**************************/}
        {/* 
        <section className="features-sec">
          <div className="features-sec-inner">
            <div className="feature-sec-1">
              <div className="heading-title">
                <div></div>
                <h4>FEATURES</h4>
                <div></div>
              </div>

              <div className="heading-sub">
                An exemplary
                <br />
                learning community
              </div>
            </div>

            <div className="feature-sec-2">
              <div>
                <img src={feat1} alt="" />
                <div>
                  <h5>Heading</h5>
                  <p>Lorem ipsum dolor sit amet consectetur</p>
                </div>
              </div>

              <div>
                <img src={feat2} alt="" />
                <div>
                  <h5>Heading</h5>
                  <p>Lorem ipsum dolor sit amet consectetur</p>
                </div>
              </div>

              <div>
                <img src={feat3} alt="" />
                <div>
                  <h5>Heading</h5>
                  <p>Lorem ipsum dolor sit amet consectetur</p>
                </div>
              </div>
            </div>

           
          </div>
        </section>
        **/}

        {/**********************************FEATURES SECTION END**************************/}

        {/*****************************ABOUT US SECTION********************************/}

        <section className="about-sec-main">
          <div className="about-sec-inner">
            <div className="about-sec1">
              <img src={about} alt="about us" />

              <div className="about-sec1-bck"></div>
            </div>

            <div className="about-sec2">
              <div className="about-sec2-heading">
                <h4>About Us</h4>
                <span></span>
              </div>
              <div className="about-sec2-sub">
                Education in continuing a proud tradition.
              </div>
              <p>
              Welcome to Zenithcorp, your trusted partner in educational excellence. We are a comprehensive platform dedicated to providing valuable resources, expert guidance, and essential services to help students excel in their academic pursuits, particularly in exams such as JAMB, WAEC, and NECO.
              </p>

              <div className="about-sec2-btn">
                <Link to="/aboutUs">
                  <button>Read More</button>
                </Link>
              </div>
            </div>
          </div>
        </section>

        {/*****************************ABOUT US SECTION END********************************/}

        {/********************************FEATURED COURSES**********************************/}

        <section className="featured-courses" id="Services" i>
          <div className="featured-courses-inner">
            <div className="featured-courses-heading">
              <div className="featured-courses-heading1">
                <span></span>
                <h3>Services</h3>
                <span></span>
              </div>

              <span className="featured-courses-heading2">Our Services</span>
            </div>

            <div className="featured-courses-card">
              {CourseCard.map((CourseCards, id) => (
                <div key={id} className="courses-card-main">
                  <div className="courses-card-icon">{CourseCards.icon}</div>
                  <div className="courses-card-title">
                    <h2>{CourseCards.head}</h2>
                  </div>
                  <div className="courses-card-text">{CourseCards.descrip}</div>

                  <div className="courses-card">
                    <div className="courses-card-desc">
                    <Link to='dashboard'>
                      <div className="courses-card-desc-inner">
                        
                        <span className="courses-card-desc-title">
                          {CourseCards.title}
                        </span>

                        <span className="courses-card-desc-icon">
                          <MdOutlineEast />
                        </span>
                       
                      </div>
                      </Link>
                    </div>
                  </div>

                  <span></span>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/********************************FEATURED COURSES**********************************/}

        {/********************************NEWS LETTER SECTION*******************************/}

        <section className="news-letter-main">
          <div className="news-letter-inner">
            <div className="news-letter-sec1">
              <h3>A Journey to Excellence</h3>
              <div className="news-letter-sec1-text">
                <p>
                By harnessing the power of technology and pedagogy, 
                we help empower learners, inspire their curiosity, 
                and support them in their pursuit of educational excellence.
                </p>
               
              </div>
            </div>

            <div className="news-letter-sec2">
              <h3>Committed to educating and nurturing all students</h3>
              <p>
              Ready to take your learning to the next level? Subscribe to our educational newsletter for a regular dose of inspiration, knowledge, and practical strategies to excel in your studies and personal growth.
              </p>
              <div className="news-letter-sec2-input">
                <input placeholder="Email address" />
                <button>Subscribe</button>
              </div>
            </div>
          </div>
        </section>

        {/********************************NEWS LETTER SECTION*******************************/}

        {/*****************************OTHER SERVICES SECTION********I**********************/}

        <section className="other-service-main">
          <div className="other-service-inner">
            <div className="other-service-heading-main">
              <div className="other-service-heading-inner">
                <span></span>
                <h3>SERIVICES</h3>
                <span></span>
              </div>

              <h3 className="other-service-heading1">Other Services</h3>
            </div>

            <div className="other-service-cards">
              <div>
                NYSC Consultation
                <p>
                Guidance and assistance for individuals navigating the National Youth Service Corps program, including registration, placement, and compliance requirements.
                </p>
                </div>

              <div>
                Academic Counseling
                <p>
                Personalized guidance and support to assist students in making informed educational decisions, setting goals, and planning their academic journey.
                </p>
                </div>

              <div>
                Test Preparation
                <p>
                Expert guidance and resources to help students excel in Jamb, WAEC, NECO, and other exams.
                </p>
                </div>
            </div>
          </div>
        </section>

        {/*****************************OTHER SERVICES SECTION********I**********************/}

        {/****************************LOGO CAROUSEL SECTION**********************************/}

        <section className="services-carousel">
          <div className="services-carousel-inner">
            <Swiper
              spaceBetween={30}
              centeredSlides={true}
              slidesPerView={3}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              modules={[Autoplay]}
              className="mySwiper"
            >
              {ServiceImage.map((ServiceImages, index) => (
                <SwiperSlide key={index} className="services-img-main">
                  <img src={ServiceImages.img} alt="service" />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </section>

        {/****************************LOGO CAROUSEL SECTION**********************************/}

        {/****************************TESTIMONIALS SECTION**********************************/}

        <Testimonial />

        {/****************************TESTIMONIALS SECTION**********************************/}
      </div>
      <Footer />
    </>
  );
};

export default Landing;
