import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

import "./consultation.scss";

const Consultation = () => {

    const form = useRef();
  
    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_1ac51j4', 'template_bq22zmd', form.current, '6PKiqZlT3_7hFoe_B')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    };


  return (
    <div>
      <section className="form-section">
        <div className="form-section-text">
          <h4>get in touch</h4>

          <h2>Write Us a Message</h2>

          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae, autem
            deleniti possimus commodi corporis tempore omnis voluptas labore
            veniam. Culpa
          </p>

          
        </div>
        {/*************************************************************** */}

        <div className="form-main">
          <form ref={form} onSubmit={sendEmail}>
            <div className="form-input-1">
              <div>
                <input type="text" name='first_name' placeholder="First Name" />
              </div>

              <div>
                {" "}
                <input type="text" name='last_name' placeholder="Last Name" />
              </div>
            </div>

            <div className="form-input-2">
              <div>
                <input type="email" name='cutomer_email' placeholder="Email" />
              </div>

              <div>
                {" "}
                <input type="text" name='subject' placeholder="Subject" />
              </div>
            </div>

            <textarea name='message' placeholder="Message" />

            <div className="contact-sub-btn">
              <button>Send Message</button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default Consultation;
