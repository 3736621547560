import React, { useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useRef } from "react";
import { Spinner } from "@chakra-ui/react";
import {FaCheckCircle } from "react-icons/fa";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  
} from '@chakra-ui/react'
import "../Airtime/airtime.scss";
import "react-toastify/dist/ReactToastify.css";

const DATA_URL = "api/wallet/vtu/data/";

const Data = () => {
  const { isOpen,onOpen, onClose } = useDisclosure()
  const errRef = useRef();
  const balRef = useRef();
  const axiosprivate = useAxiosPrivate();

  const pocket = localStorage.getItem('pocketState');

  const [loading, setLoading] = useState(false);

  const [networdId, setNetworkId] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [variationId, setvariationId] = useState("");
  const [amount, setAmount] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [balMsg, setBalMsg] = useState("");
  const [success, setSuccess] = useState(false);

  const networks = [
    {
      id: 1,
      label: "Select network",
      value: "select",
    },
    {
      id: 2,
      label: "MTN",
      value: "mtn",
    },
    {
      id: 3,
      label: "Airtel",
      value: "airtel",
    },
    {
      id: 4,
      label: "Glo",
      value: "glo",
    },
    {
      id: 5,
      label: "9mobile",
      value: "etisalat",
    },
  ];

  const variations = [
    {
      id: 1,
      label: "Select Bundle",
      value: "select",
    },
    {
      id: 2,
      label: "MTN SME Data 500MB – 30 Days",
      value: "500",
      amount: 150,
    },
    {
      id: 3,
      label: "MTN SME Data 1GB – 30 Days",
      value: "M1024",
      amount: 250,
    },
    {
      id: 4,
      label: "MTN SME Data 2GB – 30 Days",
      value: "M2024",
      amount: 500,
    },
    {
      id: 5,
      label: "MTN SME Data 3GB – 30 Days",
      value: "3000",
      amount: 800,
    },
    {
      id: 6,
      label: "MTN SME Data 5GB – 30 Days",
      value: "5000",
      amount: 1250,
    },
    {
      id: 7,
      label: "MTN SME Data 10GB – 30 Days",
      value: "10000",
      amount: 2500,
    },
    {
      id: 8,
      label: "MTN Data 6GB – 7 Days",
      value: "mtn-20hrs-1500",
      amount: 1500,
    },
    {
      id: 9,
      label: "MTN Data 30GB – 30 Days",
      value: "mtn-30gb-8000",
      amount: 8000,
    },
    {
      id: 10,
      label: "MTN Data 40GB – 30 Days",
      value: "mtn-40gb-10000",
      amount: 10000,
    },
    {
      id: 11,
      label: "MTN Data 75GB – 30 Days",
      value: "mtn-75gb-15000",
      amount: 15000,
    },

    /********GLO BUNDLES***********/
    {
      id: 12,
      label: "Glo Data 1GB – 5 Nights",
      value: "glo100x",
      amount: 100,
    },
    {
      id: 13,
      label: "Glo Data 1.25GB – 1 Day",
      value: "glo200x",
      amount: 200,
    },
    {
      id: 14,
      label: "Glo Data 1.35GB – 14 Days",
      value: "G500",
      amount: 500,
    },
    {
      id: 15,
      label: "Glo Data 5.8GB – 30 Days",
      value: "G2000",
      amount: 1950,
    },
    {
      id: 16,
      label: "Glo Data 2.9GB – 30 Days",
      value: "G1000",
      amount: 980,
    },
    {
      id: 17,
      label: "Glo Data 7.7GB – 30 Days",
      value: "G2500",
      amount: 2450,
    },
    {
      id: 18,
      label: "Glo Data 10GB – 30 Days",
      value: "G3000",
      amount: 2950,
    },
    {
      id: 19,
      label: "Glo Data 13.25GB – 30 Days",
      value: "G4000",
      amount: 3890,
    },
    {
      id: 20,
      label: "Glo Data 18.25GB – 30 Days",
      value: "G5000",
      amount: 4850,
    },
    {
      id: 21,
      label: "Glo Data 29.5GB – 30 Days",
      value: "G8000",
      amount: 7780,
    },
    {
      id: 22,
      label: "Glo Data 50GB – 30 Days",
      value: "glo10000",
      amount: 9870,
    },

    /*************AIRTEL BUNDLE********/
    {
      id: 23,
      label: "Airtel Data 500MB (Gift) – 30 Days",
      value: "AIRTEL500MB",
      amount: 150,
    },
    {
      id: 24,
      label: "Airtel Data 1GB (Gift) – 30 Days",
      value: "AIRTEL1GB",
      amount: 275,
    },
    {
      id: 25,
      label: "Airtel Data 2GB (Gift)– 30 Days",
      value: "AIRTEL2GB",
      amount: 545,
    },
    {
      id: 26,
      label: "Airtel Data 5GB (Gift)– 30 Days",
      value: "AIRTEL5GB",
      amount: 1355,
    },
    {
      id: 27,
      label: "Airtel Data 10GB (Gift)– 30 Days",
      value: "AIRTEL10GB",
      amount: 2705,
    },
    {
      id: 28,
      label: "Airtel Data 15GB (Gift)– 30 Days",
      value: "AIRTEL15GB",
      amount: 4000,
    },
    {
      id: 29,
      label: "Airtel Data 20GB (Gift)– 30 Days",
      value: "AIRTEL20GB",
      amount: 5300,
    },
    {
      id: 30,
      label: "Airtel Data 1.5GB – 30 Days",
      value: "airt-1100",
      amount: 1070,
    },
    {
      id: 31,
      label: "Airtel Data 2GB – 30 Days",
      value: "airt-1300",
      amount: 1280,
    },
    {
      id: 32,
      label: "Airtel Data 3GB – 30 Days",
      value: "airt-1650",
      amount: 1630,
    },
    {
      id: 33,
      label: "Airtel Data 4.5GB – 30 Days",
      value: "airt-2200",
      amount: 2180,
    },
    {
      id: 34,
      label: "Airtel Data 10GB – 30 Days",
      value: "airt-3300",
      amount: 3280,
    },
    {
      id: 35,
      label: "Airtel Data 20GB – 30 Days",
      value: "airt-5500",
      amount: 5480,
    },
    {
      id: 36,
      label: "Airtel Data 40GB – 30 Days",
      value: "airt-11000",
      amount: 10700,
    },
    {
      id: 37,
      label: "Airtel Data 1GB – 1 Day",
      value: "airt-330x",
      amount: 330,
    },
    {
      id: 38,
      label: "Airtel Data 750MB – 14 Days",
      value: "airt-550",
      amount: 540,
    },
    {
      id: 39,
      label: "Airtel Data 6GB – 7 Days",
      value: "airt-1650-2",
      amount: 1630,
    },

    /*******9MOBLIE BUNDLES****/
    {
      id: 40,
      label: "9mobile Data 1GB – 30 Days",
      value: "9MOB1000",
      amount: 980,
    },
    {
      id: 41,
      label: "9mobile Data 2.5GB – 30 Days",
      value: "9MOB34500",
      amount: 1980,
    },
    {
      id: 42,
      label: "9mobile Data 11.5GB – 30 Days",
      value: "9MOB8000",
      amount: 7900,
    },
    {
      id: 43,
      label: "9mobile Data 15GB – 30 Days",
      value: "9MOB5000",
      amount: 9870,
    },
  ];

  /**
    const handle = (e) =>{
    e.preventDefault();
    console.log(networdId)
    console.log(phoneNumber)
    console.log(variationId)
  }
   */

  const handleOptionChange = (e) => {
    const selectedVariation = e.target.value;
    setvariationId(selectedVariation);

    const selectedObj = variations.find(
      (variations) => variations.value === selectedVariation
    );
    const amount = selectedObj ? selectedObj.amount : "";
    setAmount(amount);
  };

  /**************HANDLE SUBMIT**************/
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      if(Number(amount) > Number(pocket)){
        setBalMsg("Insufficient Balance");
        console.log(pocket)
        setLoading(false);
      }else {
        const response = await axiosprivate.post(
          DATA_URL,
          JSON.stringify({
            phone: phoneNumber,
            network: networdId,
            variation: variationId,
            amount,
          }),
          {
            headers: { "Content-Type": "application/json" },
          }
        );
        console.log(JSON.stringify(response?.data));
        //console.log(JSON.stringify(response));
        //const accessToken = response?.data?.access;

        onOpen(true);
        setPhoneNumber("");
        setvariationId("");
        setAmount("");

      
        setSuccess(true);

        console.log(success);
        console.log(variationId);
        console.log(networdId);
        console.log(phoneNumber);
        console.log(amount);
        setLoading(false);
        //navigate(from, { replace: true });
      }
    } catch (err) {
      setLoading(false);
      console.log(variationId);
      console.log(typeof variationId);
      if (!err?.response) {
        setErrMsg("No Server Response Or check your internet connection");
      } else if (err.response?.status === 400) {
        setErrMsg("Missing Amount||Phone Number");
      } else if (err.response?.status === 500) {
        setErrMsg("Missing Amount||Phone Number");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Recharge Failed");
      }
      errRef.current.focus();
    }
  };

  /**************HANDLE SUBMIT END**************/

  return (
    <section className="airtime-main">
      <div className="airtime-inner">
        <form>
          <p
            ref={errRef}
            className={errMsg ? "errmsg" : "offscreen"}
            aria-live="assertive"
          >
            {errMsg}
          </p>
          <p
            ref={balRef}
            className={balMsg ? "errmsg" : "offscreen"}
            aria-live="assertive"
          >
            {balMsg}
          </p>

          <label>Mobile Network</label>
          <select
            value={networdId}
            onChange={(e) => setNetworkId(e.target.value)}
          >
            {networks.map((network, id) => (
              <option key={id} value={network.value}>
                {network.label}
              </option>
            ))}
          </select>

          {/**************************/}
          <label>Select Bundle</label>
          <select value={variationId} onChange={handleOptionChange}>
            {variations.map((variation, id) => (
              <option key={id} value={variation.value}>
                {variation.label}
              </option>
            ))}
          </select>

          {/************************** */}
          <label>Amount</label>
          <input
            type="number"
            placeholder="₦"
            onChange={(e) => setAmount(e.target.value)}
            value={amount}
            required
            disabled
          />

          {/**************************** */}
          <label>Receipient Number</label>
          <input
            type="number"
            placeholder="081********"
            onChange={(e) => setPhoneNumber(e.target.value)}
            value={phoneNumber}
            required
          />

          {/**************************** */}

          <button className="recharge-btn" onClick={handleSubmit}>
            {loading === false ? (
              <span> Buy Data</span>
            ) : (
              <span>
                <Spinner className="btn-load" /> Processing..
              </span>
            )}
          </button>
        </form>
      </div>

      <Modal isOpen={isOpen} onClose={onClose}>
       
        <ModalOverlay />
       
        <ModalContent>
       
          <ModalHeader>{""}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className="modal-text">
              <FaCheckCircle style={{color: "green", fontSize: "70px"}} />
             <h1>Data Sent Successfully</h1>
            </div>
          </ModalBody>
          
        </ModalContent>
       
      </Modal>
    </section>
  );
};

export default Data;
