import Layout from "../../Layouts/layout";
import C1 from "../../Assets/Image/c1.png";
import C2 from "../../Assets/Image/C2.png";
import C3 from "../../Assets/Image/C3.png";
import C4 from "../../Assets/Image/C4.png";
import C5 from "../../Assets/Image/C5.png";
import C6 from "../../Assets/Image/C6.png";
import { MdOutlineEast } from "react-icons/md";
import "./allCourse.scss"

const AllCourse = () => {

    const CourseCard = [
        { id: 1, icon: "", image: C1, title: "Creative resilience" },
        { id: 2, icon: "", image: C2, title: "Adaptability" },
        { id: 3, icon: "", image: C3, title: "Project management" },
        { id: 4, icon: "", image: C4, title: "User Interface" },
        { id: 5, icon: "", image: C5, title: "Data Tracking" },
        { id: 6, icon: "", image: C6, title: "Creative resilience" },
        { id: 7, icon: "", image: C4, title: "User Interface" },
        { id: 8, icon: "", image: C5, title: "Data Tracking" },
        { id: 9, icon: "", image: C6, title: "Creative resilience" },
      ];


  return (
    <Layout>
      <div className="all-course-main">
        <div className="banner">
          <h2>Services</h2>
        </div>
        <div className="all-course-main-inner">
        <div className="featured-courses-card">
              {CourseCard.map((CourseCards, id) => (
                <div key={id} className="courses-card-main">
                  <img src={CourseCards.image} alt="" />

                  <div className="courses-card">
                    <div className="courses-card-desc">
                      <div className="courses-card-desc-inner">
                        <span className="courses-card-desc-title">
                          {CourseCards.title}
                        </span>

                        <span className="courses-card-desc-icon">
                          <MdOutlineEast />
                        </span>
                      </div>
                    </div>
                  </div>

                  <span></span>
                </div>
              ))}
            </div>
        </div>
      </div>
    </Layout>
  );
};

export default AllCourse;
