import "./sideBar.scss";

import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  ProSidebarProvider,
  sidebarClasses,
  menuClasses,
} from "react-pro-sidebar";
import { Link } from "react-router-dom";

export const handleLogout = () => {
  localStorage.removeItem("refresh");
  window.location.href = "/login";
};

const SideBar = () => {
 
  return (
    <>
      <ProSidebarProvider>
        <div className="sidebar-parent">
          <Sidebar
            className="sidebar-inner"
            rootStyles={{
              [`.${sidebarClasses.container}`]: {
                backgroundColor: "transparent",
                height: "100%",
              },
            }}
          >
            <div className="sidebar-logo">
              <div className="sidebar-logo-inner">
                <h1>ZENITHCORP</h1>
              </div>
            </div>
            <Menu
              className="menu-items-main"
              menuItemStyles={{
                button: ({ level, active, disabled, hover }) => {
                  // only apply styles on first level elements of the tree
                  if (level === 0)
                    return {
                      color: disabled ? "#f5d9ff" : "white",
                      backgroundColor: active ? "red" : undefined,
                    };
                },
              }}
              rootStyles={{
                [`.${menuClasses.button}`]: {
                  "&:hover": {
                    color: "black",
                  },
                  "&:focus": {
                    backgroundColor: "black",
                    color: "white",
                  },
                },
              }}
            >
              <MenuItem icon={""} component={<Link to="/dashboard" />}>
                Dashboard
              </MenuItem>
              <MenuItem icon={""} component={<Link to="transaction" />}>
                Transaction
              </MenuItem>
              <SubMenu label="Bill Payments">
                <MenuItem icon={""} component={<Link to="airtime" />}>
                  Airtime
                </MenuItem>
                <MenuItem icon={""} component={<Link to="data" />}>
                  Data
                </MenuItem>
                <MenuItem icon={""}>
                  Electricity <sup>Coming Soon</sup>
                </MenuItem>
                <MenuItem icon={""}>
                  Cable/TV <sup>Coming Soon</sup>
                </MenuItem>
              </SubMenu>

              <SubMenu label="NYSC">
                <MenuItem icon={""}>
                  
                  Consultation <sup>Coming Soon</sup>
                </MenuItem>
              </SubMenu>

              <SubMenu label="Educational Cards/Pin">
                <MenuItem icon={""}>
                  
                  Jamb <sup>Coming Soon</sup>
                </MenuItem>
                <MenuItem icon={""}>
                  
                  Result Checker <sup>Coming Soon</sup>
                </MenuItem>
              </SubMenu>

              <SubMenu label="Other Services">
                <MenuItem icon={""}>
                  
                  Web Development <sup>Coming Soon</sup>
                </MenuItem>
                <MenuItem icon={""} component={<Link to="consultation" />}>
                  
                  Consultation
                </MenuItem>
                <MenuItem icon={""}>
                  
                  Graphic Design <sup>Coming Soon</sup>
                </MenuItem>
              </SubMenu>
            </Menu>
          </Sidebar>

          <div className="logout-btn">
            <button onClick={handleLogout}>Log Out</button>
          </div>
        </div>
      </ProSidebarProvider>
    </>
  );
};

export default SideBar;
