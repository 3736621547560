import React from 'react'
import '../Airtime/airtime.scss'

const Electricity = () => {

  return (

    
       <section className='airtime-main'>

        <div className='airtime-inner'>

                <form>
                    <label>Mobile Network</label>
                    <select>
                        <option>MTN</option>
                        <option>GLO</option>
                        <option>9MOBILE</option>
                        <option>AIRTEL</option>
                    </select>

                   { /**************************/ }
                    <label>Phone Number</label>
                    <input placeholder='081********(11 digits)' required/>

                   { /************************** */}
                    <label>Amount</label>
                    <input placeholder='#100' required/>

                    <button className='recharge-btn'>
                        Recharge
                    </button>
                </form>
        </div>

       </section>
    

  )
}

export default Electricity