import { useRef, useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Spinner } from "@chakra-ui/react";
import { FaEyeSlash, FaEye } from "react-icons/fa";

import "./login.scss";
import axios from "../../api/axios";
import useAuth from "../../hooks/useAuth";
import Cookies from "js-cookie";

const LOGIN_URL = "api/auth/sign_in/";

const Login = () => {
  //const router = useNavigate();
  const navigate = useNavigate();
  const location = useLocation();

  const from = location.state?.from?.pathname || "/dashboard";

  const { setAuth } = useAuth();

  const userRef = useRef();
  const errRef = useRef();

  const [loading, setLoading] = useState(false);
  /********************************** */

  const [passwordType, setPasswordType] = useState("password");

  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);

  /********************************** */

  /**************USE EFFECT*************/
  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [user, pwd]);

  /**************USE EFFECT END*************/

  /********HANDLES PASSWORD HIDE********/

  const togglePassword = (e) => {
    e.preventDefault();
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  /********HANDLES PASSWORD HIDE********/

  /**************HANDLE SUBMIT**************/
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({
          username: user,
          password: pwd,
        }),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      //console.log(JSON.stringify(response?.data));
      //console.log(JSON.stringify(response));
      const accessToken = response?.data?.access;

      //const roles = response?.data?.roles;
      Cookies.set("refresh", response?.data?.refresh, { secure: true });
      //localStorage.setItem("refresh", response?.data?.refresh);
      localStorage.setItem("username", user);

      setAuth({ user, pwd, accessToken });

      //router('/dashboard');
      setUser("");
      setPwd("");
      setSuccess(true);
      console.log(success);
      navigate(from, { replace: true });
    } catch (err) {
      setLoading(false);
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized||Not a registered user");
      } else {
        setErrMsg("Login Failed");
      }
      errRef.current.focus();
    }
  };

  /**************HANDLE SUBMIT END**************/

  return (
    <div className="login-main">
      <div className="login-inner">
        {/********************************* */}
        <div className="login-sec1">
          <div className="login-logo">ZENITHCORP</div>
          <div className="login-form-main">
            <form onSubmit={handleSubmit}>
              <p
                ref={errRef}
                className={errMsg ? "errmsg" : "offscreen"}
                aria-live="assertive"
              >
                {errMsg}
              </p>
              <div className="form-heading">
                <h1>Welcome Back</h1>

                <h5>Login to continue</h5>
              </div>

              <input
                type="text"
                id="username"
                ref={userRef}
                autoComplete="off"
                onChange={(e) => setUser(e.target.value)}
                value={user}
                required
              />

              <div className="password-box">
                <input
                  type={passwordType}
                  placeholder="Password"
                  id="password"
                  onChange={(e) => setPwd(e.target.value)}
                  value={pwd}
                  required
                />
                <button className="btn" onClick={togglePassword}>
                  {passwordType === "password" ? (
                    <span className="pas-icon">
                      <FaEye />
                    </span>
                  ) : (
                    <span className="pas-icon">
                      <FaEyeSlash />
                    </span>
                  )}
                </button>
              </div>

              <div className="login-forgot-pass">
                <h4>Forgot Password?</h4>
              </div>

              <div className="register-btn-main">
                <button>
                  {loading === false ? (
                    <span> Sign In</span>
                  ) : (
                    <span>
                      <Spinner className="btn-load" />
                      Loading
                    </span>
                  )}
                </button>
              </div>

              <div className="signup-continue">
                <span className="span-line"></span>
                <h6>
                  Don't have an account?
                  <Link to="/signUp">
                    <span> Register</span>
                  </Link>
                </h6>
                <span className="span-line"></span>
              </div>
            </form>
          </div>
        </div>

        {/*********************************** */}

        {/*********************************** */}
      </div>
    </div>
  );
};

export default Login;
