import Layout from "../../Layouts/layout";
import "./contactus.scss";

const ContactUs = () => {
  return (
    <Layout>
    <div className="contact-us-main">
      <div className="banner">
        <h2>HOME/CONTACT</h2>
      </div>

      <div className="contact-us-inner">

            <section className="info-section">

                <div className="info info-phone">
                    <span></span>

                    <span>
                      <h2>Our Phone</h2>
                      <h5>+234 705 530 6984</h5>
                    </span>
                </div>

                <div className="info info-mail">
                <span></span>

                <span>
                <h2>Our Email</h2>
                <h5>support@zenithcorp.com.ng</h5>
                </span>
                </div>

                <div className="info info-address">
                <span></span>

                <span>
                <h2>Location</h2>
                <h5>Lagos, Nigeria</h5>
                </span>
                </div>

            </section>

{/******************************************************** */}
            <section className="form-section-contact">

              <div className="form-section-text">
                <h4>get in touch</h4>

                <h2>Write Us a Message</h2>

                <p>We're here to assist you. Reach out to us for inquiries, consultations, or any other questions.<br/>
                Fill out our  contact form , and our dedicated support team will respond to your inquiry promptly.
                </p>

                <div className="form-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
{/*************************************************************** */}

              <div className="form-main">

                  <form>
                    <div className="form-input-1">

                      <div><input type="text" placeholder="First Name" /></div>

                     <div> <input type="text" placeholder="Last Name" /></div>
                    </div>

                    <div className="form-input-2">
                     <div><input type="email" placeholder="Email" /></div>

                    <div> <input type="text" placeholder="Subject" /></div>
                    </div>

                    <textarea placeholder="Message"/>

                      <div className="contact-sub-btn">
                      <button>Send Message</button>
                      </div>
                   
                  </form>
              </div>

            </section>
      </div>
    </div>
    </Layout>
  );
};

export default ContactUs;
