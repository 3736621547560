import { Link } from "react-router-dom";
import "./404.scss"

export default function NotFound() {
    return (
        <div className="error-main">
            <section className="error-inner">
                <h1>ERROR 404</h1>
                <h3>Page not found</h3>

                <Link to={"/"}>
                <p>Go back to home page</p>
                </Link>
              
            </section>
            
        </div>
    )
}