
import { AuthProvider } from '../../context/authProvider';
import { ChakraProvider } from '@chakra-ui/react'
import Router from '../../Routes/router';
import './App.scss';
import WalletProvider from '../../context/walletProvider';


function App() {
  return (
    <div className="App">
      <ChakraProvider>
    <AuthProvider>
      <WalletProvider>
      <Router/>
      </WalletProvider>
      </AuthProvider>
      </ChakraProvider>
    
    </div>
  );
}

export default App;
