import { Link } from "react-router-dom";
import Layout from "../../Layouts/layout";
import Testimonial from "../../components/testimonials/testimonial";
import "./aboutUs.scss";

const AboutUs = () => {
  return (
    <Layout>
        <div className="aboutUs-main">
      <section className="who-we-are">
        <div className="who-we-are-inner">
          <div className="who-we-are-img"></div>

          <div className="who-we-are-text">
            <h1>Who We Are?</h1>

            <p>
            Welcome to Zenithcorp, your trusted partner in educational excellence. We are a comprehensive platform dedicated to providing valuable resources, expert guidance, and essential services to help students excel in their academic pursuits, particularly in exams such as JAMB, WAEC, and NECO.
            </p>

            <p>
            At Zenithcorp, we understand the challenges students face during their educational journey. That's why we offer a wide range of services, including the sale of educational e-pins, consulting services, and access to essential resources. Our platform is your one-stop destination for acquiring JAMB, WAEC, and NECO pins, as well as other educational utilities.
            </p>

            <p>
            In addition to our services, we take pride in offering expert guidance to students. Our team of experienced professionals is dedicated to providing personalized assistance, study tips, and exam strategies to help students maximize their potential and achieve outstanding results in their academic pursuits.
            </p>

            <div className="who-we-are-btn">
              <Link to="/signup">
                <button>Register</button>
                </Link>
            </div>
          </div>
        </div>
      </section>

      {/*********************************************************************** */}

      <section className="our-story">
        <div className="our-story-inner">

            <div className="our-story-text">
                <h1>Our Mission</h1>

                <p>
                At Zenithcorp, our mission is to empower students with the resources, guidance, and services they need to achieve educational excellence. We are committed to providing a comprehensive platform that supports students in their academic journey, particularly in exams such as JAMB, WAEC, and NECO. Through our range of educational e-pins, consulting services, expert guidance, and access to essential resources, we aim to inspire, equip, and guide students towards success. We strive to foster a community where students can thrive, discover their full potential, and build a strong foundation for lifelong learning. Our ultimate goal is to make a positive impact in the lives of students by enabling them to excel academically and confidently pursue their dreams.
                </p>
              

            </div>

            <div className="our-story-img">
    
            </div>

        </div>
        {/**.... */}
        <div className="our-story-inner2">

            <div className="our-story-text">
                <h1>Why Choose US</h1>

                <p>
                  <span>Expertise and Experience: </span>With years of experience in the education industry, our team of dedicated educators brings a wealth of knowledge and expertise to help students excel academically. We have a proven track record of success and a deep understanding of the educational landscape.
                </p>
                <p>
                  <span>Personalized Approach:  </span>We believe in the power of personalized education. Our services are tailored to meet the unique needs and learning styles of each student. We take the time to understand their strengths, challenges, and goals, allowing us to provide targeted support and guidance.
                </p>
                <p>
                  <span>Comprehensive Services: </span>We offer a wide range of educational services under one roof. From tutoring and test preparation to academic counseling and educational consulting, we are a one-stop solution for all your educational needs. Our diverse offerings ensure that you can find the support you need at every step of your educational journey.
                </p>
                <p>
                  <span>Commitment to Student Success: </span>Our primary focus is on the success of our students. We go above and beyond to provide them with the tools, resources, and support they need to achieve their academic goals. We celebrate their accomplishments and take pride in their progress.
                </p>
                <p>
                  <span>Supportive Learning Environment: </span>We believe that learning thrives in a supportive and nurturing environment. Our educators create a safe and encouraging space where students can ask questions, explore new ideas, and develop their confidence. We foster a positive and motivating atmosphere that inspires growth and learning.
                </p>
                <p>
                  <span>Customized Programs and Resources:  </span>Our educational programs and resources are designed to address the specific needs and challenges of each student. Whether it's individualized tutoring, targeted study materials, or personalized study plans, we provide tailored solutions to maximize learning outcomes.
                </p>

            </div>


        </div>
      </section>

    {/******************************************************************************** */}

     {/********************************NEWS LETTER SECTION*******************************/}

     <section className="news-letter-main">
          <div className="news-letter-inner">
            <div className="news-letter-sec1">
              <h3>A Journey to Excellence</h3>
              <div className="news-letter-sec1-text">
                <p>
                By harnessing the power of technology and pedagogy, 
                we help empower learners, inspire their curiosity, 
                and support them in their pursuit of educational excellence.
                </p>
               
              </div>
            </div>

            <div className="news-letter-sec2">
              <h3>Committed to educating and nurturing all students</h3>
              <p>
              Ready to take your learning to the next level? Subscribe to our educational newsletter for a regular dose of inspiration, knowledge, and practical strategies to excel in your studies and personal growth.
              </p>
              <div className="news-letter-sec2-input">
                <input placeholder="Email address" />
                <button>Subscribe</button>
              </div>
            </div>
          </div>
        </section>

        {/********************************NEWS LETTER SECTION*******************************/}

        {/*****************************************TESTIMONIAL******************************8 */}

                <Testimonial/>

        {/*****************************************TESTIMONIAL******************************8 */}
      </div>
    </Layout>
  );
};

export default AboutUs;
