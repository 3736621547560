// WalletProvider.js
import React, { useState } from 'react';
import WalletContext from './walletContext';

const WalletProvider = ({ children }) => {
  const [pocket, setPocket] = useState(0);

  return (
    <WalletContext.Provider value={{ pocket, setPocket }}>
      {children}
    </WalletContext.Provider>
  );
};

export default WalletProvider;