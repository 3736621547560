import "./walletCard.scss";
import usersaving from "../../Assets/Image/usersaving.svg";
import { Link } from "react-router-dom";
import { Spinner } from "@chakra-ui/react";
import { FaSync } from "react-icons/fa";
import "react-toastify/dist/ReactToastify.css";

//import useAxiosPrivate from "../../hooks/useAxiosPrivate";



const WalletCard = ({fetchPockets, pocket, loading, errMsg}) => {

  //
  
  return (
    <div className="wallet-card-main">
      <div className="card-detail">
        <img src={usersaving} alt="icon" />
        <h3 className="card-title">Wallet Balance</h3>
        <p>{errMsg}</p>
        <div className="wallet-pocket">
          {loading === false ? (
            <h4>{pocket}</h4>
          ) : (
            <span>
              <Spinner className="btn-load" />
            </span>
          )}

          <button onClick={fetchPockets} className="pocket-btn">
            <FaSync />
          </button>
        </div>

        <div className="wallet-fund-btn">
          <Link to="fundAccount">
            <button>Fund Account</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default WalletCard;
