import React, { useState, useEffect, useRef } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import "./transaction.scss";

import Paginate from "../../utils/pagination";

const TRANSACTIONS_URL = "api/wallet/transactions/";
const PAYSTACK_URL = "api/wallet/charge/";

const Transaction = () => {
  const timeFormat = { month: "short", day: "numeric", year: "numeric" };
  const axiosprivate = useAxiosPrivate();
  const errRef = useRef();

  
  const [transactions, setTransactions] = useState([]);
  const [deposit, setDeposit] = useState([]);
  const totalTransactions= [...transactions, ...deposit];
  const [errMsg, setErrMsg] = useState("");

  const fetchTansactions = async () => {
    try {
      const response = await axiosprivate.get(
        TRANSACTIONS_URL,
        JSON.stringify({})
      );
      //console.log(response?.data);
      const transactions = response?.data;

      setTransactions(transactions);
      console.log(transactions)
    } catch (err) {
      console.log(err);
      setErrMsg("Couldn't fetch Transactions");
    }
  };
const fetchPaystack = async () => {
        
        try {
          const response = await axiosprivate.get(PAYSTACK_URL, JSON.stringify({}));
          console.log(response?.data);
          const deposit = response?.data;
          
          
          setDeposit(deposit);
          console.log(deposit)
        } catch (err) {
          console.log(err);
          setErrMsg("Couldn't fetch Transactions");
        }
      };
   
     

  useEffect(() => {
    fetchTansactions();
    fetchPaystack()

    // eslint-disable-next-line
  }, []);

  // Pagination//////////////////////
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(4);

  // ...

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = totalTransactions.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const previousPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== Math.ceil(totalTransactions.length / postsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <>
      <section className="transaction-main">
        <p
          ref={errRef}
          className={errMsg ? "errmsg" : "offscreen"}
          aria-live="assertive"
        >
          {errMsg}
        </p>

        <div className="table-main">
          { totalTransactions.length === 0 ? (
            <div className="no-users">
              <h1>NO Transactions Found</h1>
            </div>
          ) : (
            <div className="transac-box-main">
              {currentPosts.map((transaction, index) => (
                <div key={index} className="transac-box-inner">
                  <span className="action">{
                  transaction.message === 'Airtime successfully delivered' ? 'Airtime' : 
                  transaction.message === 'Data successfully delivered' ? 'Data' : 
                  transaction.message === 'Could not communicate with server' ? 'Error' : 'Deposit'
                  }</span>
                  <span className="message">{transaction.message}</span>
                  <span className="amount">₦{transaction.amount}</span>
                  <span
                    className="status"
                    style={{
                      backgroundColor:
                        transaction.status === "failure"
                          ? "rgba(248, 39, 39, 0.794)"
                          : "rgba(39, 214, 39, 0.787)",
                    }}
                  >
                    {transaction.status}
                  </span>
                  <span className="date">
                    {new Date(transaction.date_added).toLocaleString(
                      "en-US",
                      timeFormat
                    )}
                  </span>
                </div>
              ))}

              <Paginate
                postsPerPage={postsPerPage}
                totalPosts={transactions.length}
                paginate={paginate}
                previousPage={previousPage}
                nextPage={nextPage}
              />
            </div>

           
          )}
        </div>
      </section>
    </>
  );
};

export default Transaction;
