import React from "react";

import { Link } from "react-router-dom";
import { HamburgerIcon } from "@chakra-ui/icons";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  //MenuItemOption,
  //MenuGroup,
  //MenuOptionGroup,
  //MenuDivider,
} from "@chakra-ui/react";
import "./header.scss";

const Header = () => {
  return (
    <header className="header">
      <div className="header-inner">
        <div className="header-logo">
          <h1>ZENITHCORP</h1>
        </div>

        <nav className="nav-main">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <a href="/#Services">Services</a>
            </li>
            <li>
              <Link to="/aboutUs">About Us</Link>
            </li>
            <li>
              <Link to="#">Blog</Link>
            </li>
            <li>
              <Link to="/contact-us">Contact US</Link>
            </li>
          </ul>
        </nav>

        {/*************************Responsive hamburger Menu********************************/}
        <nav className="nav-responsive">
        <Menu className="responsive">
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<HamburgerIcon />}
            colorScheme='gray'
            fontSize='25px'
           
            className="res-btn"
          />
          <MenuList>
            <Link to="/">
            <MenuItem icon={""} >
            Home
            </MenuItem>
            </Link>

            <Link to="/aboutUs">
            <MenuItem icon={""}>
             About Us
            </MenuItem>
            </Link>

            <Link to="/">
            <MenuItem icon={""} >
              Blog 
            </MenuItem>
            </Link>

            <Link to="/contact-us">
            <MenuItem icon={""} >
              Contact Us
            </MenuItem>
            </Link>
          </MenuList>
        </Menu>
        </nav>
        {/*******************End of responsive navbar********************************/}
      </div>
    </header>
  );
};

export default Header;
