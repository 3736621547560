import { useState, useRef, useEffect } from "react";
import { Spinner } from "@chakra-ui/react";
import "./profile.scss";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

//const PASSWORD_URL = "api/auth/reset_password/";

const Profile = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [loading, setLoading] = useState(true);

  const errRef = useRef();
  const axiosprivate = useAxiosPrivate();
  const userName = localStorage.getItem("username");

  const USERNAME_URL = `api/auth/user/${userName}`;
  const PASSWORD_URL = "api/auth/reset_password/";

  const fetchDetails = async () => {
    try {
      const response = await axiosprivate.get(USERNAME_URL, JSON.stringify({}));
      //console.log(response?.data);
      const transactions = response?.data;

      setName(transactions.user.username);
      setEmail(transactions.user.email);
      setLoading(false);
      console.log(transactions);
    } catch (err) {
      console.log(err);
      setErrMsg("Couldn't fetch Your Account Detatils");
    }
  };
  const updatePassword = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosprivate.put(
        PASSWORD_URL,
        JSON.stringify({
          old_password: oldPass,
          new_password: newPass,
        }),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      console.log(response?.data);

      console.log("Password update success");
    } catch (err) {
      console.log(err);
      setErrMsg("incorrect old password");
    }
  };

  useEffect(() => {
    fetchDetails();
    //fetchPaystack()

    // eslint-disable-next-line
  }, []);

  return (
    <main className="profile-main">
      <section className="profile-section">
        <form>
          <p
            ref={errRef}
            className={errMsg ? "errmsg" : "offscreen"}
            aria-live="assertive"
          >
            {errMsg}
          </p>
          <div>
            <label>USER NAME</label>
            {loading === true ? (
              <span>
                {" "}
                <Spinner className="btn-load" />
              </span>
            ) : (
              <input value={name} readOnly />
            )}
          </div>

          <div>
            <label>EMAIL</label>

            {loading === true ? (
              <span>
                <Spinner className="btn-load" />
              </span>
            ) : (
              <input value={email} readOnly />
            )}
          </div>

          <div>
            <label>PASSWORD</label>
            <input
              value={oldPass}
              onChange={(e) => setOldPass(e.target.value)}
              placeholder="Old Password"
            />

            <input
              value={newPass}
              onChange={(e) => setNewPass(e.target.value)}
              placeholder="New password"
            />
            <button onClick={updatePassword}>Update Password</button>
          </div>
        </form>
      </section>
    </main>
  );
};

export default Profile;
