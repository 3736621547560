import "./signup.scss";
import { useRef, useState, useEffect } from "react";
import axios from "../../api/axios";
import {
  faCheck,
  faTimes,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { FaEyeSlash, FaEye, FaCheckCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Spinner } from "@chakra-ui/react";

const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const EMAIL_REGEX = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
const REGISTER_URL = "api/auth/sign_up/";

const Signup = () => {
  const userRef = useRef();
  const errRef = useRef();

  const [loading, setLoading] = useState(false);

  /********************************** */

  const [passwordType, setPasswordType] = useState("password");
  const [passwordSureType, setPasswordSureType] = useState("password");

  const [pwd, setPwd] = useState("");
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [matchPwd, setMatchPwd] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [emailInput, setEmailInput] = useState("");
  const [validEmail, setValidEmail] = useState("");
  const [emailFocus, setEmailFocus] = useState("");

  const [user, setUser] = useState("");
  const [validName, setValidName] = useState(false);
  const [userFocus, setUserFocus] = useState(false);

  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);

  /********************************** */

  /********HANDLES PASSWORD HIDE********/

  const togglePassword = (e) => {
    e.preventDefault();
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const togglePasswordSure = (e) => {
    e.preventDefault();
    if (passwordSureType === "password") {
      setPasswordSureType("text");
      return;
    }
    setPasswordSureType("password");
  };

  /****************************** */

  /***************USE EFFECTS****************************/

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setValidName(USER_REGEX.test(user));
  }, [user]);

  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(emailInput));
  }, [emailInput]);

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(pwd));
    setValidMatch(pwd === matchPwd);
  }, [pwd, matchPwd]);

  useEffect(() => {
    setErrMsg("");
  }, [user, pwd, matchPwd]);

  /***************USE EFFECTS END************************/

  /****************************** */
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    // if button enabled with JS hack
    const v1 = USER_REGEX.test(user);
    const v2 = PWD_REGEX.test(pwd);
    const v3 = EMAIL_REGEX.test(emailInput);
    if (!v1 || !v2 || !v3) {
      setErrMsg("Invalid Entry");
      return;
    }
    try {
      const response = await axios.post(
        REGISTER_URL,
        JSON.stringify({
          username: user,
          email: emailInput,
          password: pwd,
        }),
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      //console.log(response?.data);
      //console.log(response?.accessToken);
      console.log(JSON.stringify(response));
      setSuccess(true);
      //clear state and controlled inputs
      //need value attrib on inputs for this
      setUser("");
      setPwd("");
      setMatchPwd("");
      setEmailInput("");
    } catch (err) {
      setLoading(false);
      if (!err?.response) {
        setErrMsg("No Server Response Or check your internet connection");
      } else if (err.response?.status === 409) {
        setErrMsg("Username Taken");
      } else if (err.response?.status === 401) {
        setErrMsg("Please complete all fields");
      } else {
        setErrMsg("Registration Failed");
      }
      errRef.current.focus();
    }
  };

  /***************** ***************/

  /********HANDLES PASSWORD HIDE********/

  return (
    <div className="signup-main">
      {success ? (
        <section className="signup-success">
          <div className="signup-success-inner">
            <span>
              <FaCheckCircle />
            </span>

            <h1>Success!</h1>
            <p>
              <Link to="/login">Sign In</Link>
            </p>
          </div>
        </section>
      ) : (
        <div className="signup-inner">
          {/********************************* */}
          <div className="signup-sec1">
            <div className="signup-logo">ZENITHCORP</div>
            <div className="signup-form-main">
              <p
                ref={errRef}
                className={errMsg ? "errmsg" : "offscreen"}
                aria-live="assertive"
              >
                {errMsg}
              </p>
              <form onSubmit={handleSubmit}>
                <div className="form-heading">
                  <h1>Welcome to ZENITHCORP</h1>

                  <h5>Signup to begin with us</h5>
                </div>

                <div className="input-box">
                  <input
                    type="text"
                    id="username"
                    ref={userRef}
                    autoComplete="off"
                    onChange={(e) => setUser(e.target.value)}
                    value={user}
                    required
                    aria-invalid={validName ? "false" : "true"}
                    aria-describedby="uidnote"
                    onFocus={() => setUserFocus(true)}
                    onBlur={() => setUserFocus(false)}
                  />

                  <div className="input-check">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className={validName ? "valid" : "hide"}
                    />
                    <FontAwesomeIcon
                      icon={faTimes}
                      className={validName || !user ? "hide" : "invalid"}
                    />
                  </div>
                </div>

                <p
                  id="uidnote"
                  className={
                    userFocus && user && !validName
                      ? "instructions"
                      : "offscreen"
                  }
                >
                  <FontAwesomeIcon icon={faInfoCircle} />
                  4 to 24 characters.
                  <br />
                  Must begin with a letter.
                  <br />
                  Letters, numbers, underscores, hyphens allowed.
                </p>

                <div className="input-box">
                  <input
                    type="email"
                    placeholder="Email"
                    value={emailInput}
                    onFocus={() => setEmailFocus(true)}
                    onChange={(e) => setEmailInput(e.target.value)}
                  />
                  <div className="input-check">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className={validEmail ? "valid" : "hide"}
                    />
                    <FontAwesomeIcon
                      icon={faTimes}
                      className={validEmail || !emailInput ? "hide" : "invalid"}
                    />
                  </div>
                </div>
                <p
                  id="uidnote"
                  className={
                    emailFocus && emailInput && !validEmail
                      ? "instructions"
                      : "offscreen"
                  }
                >
                  <FontAwesomeIcon icon={faInfoCircle} />
                  Invalid Email
                </p>

                <div className="password-box">
                  <input
                    type={passwordType}
                    id="password"
                    onChange={(e) => setPwd(e.target.value)}
                    value={pwd}
                    required
                    aria-invalid={validPwd ? "false" : "true"}
                    aria-describedby="pwdnote"
                    onFocus={() => setPwdFocus(true)}
                    onBlur={() => setPwdFocus(false)}
                  />
                  <button className="btn" onClick={togglePassword}>
                    {passwordType === "password" ? (
                      <span className="pass-icon">
                        <FaEye />
                      </span>
                    ) : (
                      <span className="pass-icon">
                        <FaEyeSlash />
                      </span>
                    )}
                  </button>

                  <div className="input-check">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className={validPwd ? "valid" : "hide"}
                    />
                    <FontAwesomeIcon
                      icon={faTimes}
                      className={validPwd || !pwd ? "hide" : "invalid"}
                    />
                  </div>
                </div>
                <p
                  id="pwdnote"
                  className={
                    pwdFocus && !validPwd ? "instructions" : "offscreen"
                  }
                >
                  <FontAwesomeIcon icon={faInfoCircle} />
                  8 to 24 characters.
                  <br />
                  Must include uppercase and lowercase letters, a number and a
                  special character.
                  <br />
                  Allowed special characters:{" "}
                  <span aria-label="exclamation mark">!</span>{" "}
                  <span aria-label="at symbol">@</span>{" "}
                  <span aria-label="hashtag">#</span>{" "}
                  <span aria-label="dollar sign">$</span>{" "}
                  <span aria-label="percent">%</span>
                </p>

                <div className="password-box">
                  <input
                    type={passwordSureType}
                    id="confirm_pwd"
                    onChange={(e) => setMatchPwd(e.target.value)}
                    value={matchPwd}
                    required
                    aria-invalid={validMatch ? "false" : "true"}
                    aria-describedby="confirmnote"
                    onFocus={() => setMatchFocus(true)}
                    onBlur={() => setMatchFocus(false)}
                  />
                  <button className="btn" onClick={togglePasswordSure}>
                    {passwordSureType === "password" ? (
                      <span className="pas-icon">
                        <FaEye />
                      </span>
                    ) : (
                      <span className="pas-icon">
                        <FaEyeSlash />
                      </span>
                    )}
                  </button>

                  <div className="input-check">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className={validMatch && matchPwd ? "valid" : "hide"}
                    />
                    <FontAwesomeIcon
                      icon={faTimes}
                      className={validMatch || !matchPwd ? "hide" : "invalid"}
                    />
                  </div>
                </div>
                <p
                  id="confirmnote"
                  className={
                    matchFocus && !validMatch ? "instructions" : "offscreen"
                  }
                >
                  <FontAwesomeIcon icon={faInfoCircle} />
                  Must match the first password input field.
                </p>

                <div className="signup-agree">
                  <input type="checkbox" />
                  <h4>
                    I agree to company terms and condition and privacy policy .
                  </h4>
                </div>

                <div className="register-btn-main">
                  <button>
                    {loading === false ? (
                      <span>Register</span>
                    ) : (
                      <span>
                        <Spinner className="btn-load" />
                        Loading
                      </span>
                    )}
                  </button>
                </div>

                <div className="signup-continue">
                  <span></span>
                  <h6>
                    Have an account already? <Link to="/Login">Login</Link>
                  </h6>
                  <span></span>
                </div>
              </form>
            </div>
          </div>

          {/*********************************** */}

          {/*********************************** */}
        </div>
      )}
    </div>
  );
};

export default Signup;
