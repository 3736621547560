import React, { useState } from "react";
import "./airtime.scss";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useRef } from "react";
import { Spinner } from "@chakra-ui/react";

import { FaCheckCircle } from "react-icons/fa";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";

const AIRTIME_URL = "api/wallet/vtu/airtime/";

const Airtime = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const errRef = useRef();
  const balRef = useRef();
  const axiosprivate = useAxiosPrivate();

  const pocket = localStorage.getItem("pocketState");
  const [loading, setLoading] = useState(false);

  const [networdId, setNetworkId] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [amount, setAmount] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [balMsg, setBalMsg] = useState("");
  const [success, setSuccess] = useState(false);

  const options = [
    {
      id: 1,
      label: "Select network",
      value: "select",
    },
    {
      id: 2,
      label: "MTN",
      value: "mtn",
    },
    {
      id: 3,
      label: "Airtel",
      value: "airtel",
    },
    {
      id: 4,
      label: "Glo",
      value: "glo",
    },
    {
      id: 5,
      label: "9mobile",
      value: "etisalat",
    },
  ];

  /**************HANDLE SUBMIT**************/
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (Number(amount) > Number(pocket)) {
        setBalMsg("Insufficient Balance");
        console.log(pocket);
        setLoading(false);
      } else {
        const response = await axiosprivate.post(
          AIRTIME_URL,
          JSON.stringify({
            phone: phoneNumber,
            network: networdId,
            amount: amount,
          }),
          {
            headers: { "Content-Type": "application/json" },
          }
        );
        console.log(JSON.stringify(response?.data));
        //console.log(JSON.stringify(response));
        //const accessToken = response?.data?.access;
        onOpen(true);
        setPhoneNumber("");
        setAmount("");
        setNetworkId("");
        setSuccess(true);

        console.log(success);
        setLoading(false);
        //navigate(from, { replace: true });
      }
    } catch (err) {
      setLoading(false);
      console.log(amount);
      console.log(typeof amount);
      if (!err?.response) {
        setErrMsg("No Server Response||check your internet connection");
      } else if (err.response?.status === 400) {
        setErrMsg("Missing Amount||Phone Number");
      } else if (err.response?.status === 500) {
        setErrMsg("Missing Amount||Phone Number");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized||please log in again");
      } else {
        setErrMsg("Recharge Failed");
      }
      errRef.current.focus();
    }
  };

  /**************HANDLE SUBMIT END**************/

  return (
    <section className="airtime-main">
      <div className="airtime-inner">
        <form>
          <p
            ref={errRef}
            className={errMsg ? "errmsg" : "offscreen"}
            aria-live="assertive"
          >
            {errMsg}
          </p>
          <p
            ref={balRef}
            className={balMsg ? "errmsg" : "offscreen"}
            aria-live="assertive"
          >
            {balMsg}
          </p>

          <label>Mobile Network</label>
          <select
            value={networdId}
            onChange={(e) => setNetworkId(e.target.value)}
          >
            {options.map((option, id) => (
              <option key={id} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>

          {/**************************/}
          <label>Phone Number</label>
          <input
            type="text"
            pattern="[0-9]*"
            placeholder="081********(11 digits)"
            required
            onChange={(e) => setPhoneNumber(e.target.value)}
            value={phoneNumber}
          />

          {/************************** */}
          <label>Amount</label>
          <input
            type="text"
            pattern="[0-9]*"
            placeholder="#100"
            required
            onChange={(e) => setAmount(e.target.value)}
            value={amount}
          />

          <button className="recharge-btn" onClick={handleSubmit}>
            {loading === false ? (
              <span> Recharge</span>
            ) : (
              <span>
                <Spinner className="btn-load" /> Processing..
              </span>
            )}
          </button>
        </form>
      </div>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>{""}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className="modal-text">
              <FaCheckCircle style={{ color: "green", fontSize: "70px" }} />
              <h1>Recharge Successful</h1>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </section>
  );
};

export default Airtime;
