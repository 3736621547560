import * as React from "react";
import "./drawer.scss";
import { Link } from "react-router-dom";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  ProSidebarProvider,
  sidebarClasses,
  menuClasses,
} from "react-pro-sidebar";
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Button,
} from "@chakra-ui/react";

import { FaBars } from "react-icons/fa";

export default function PlacementExample() {
  const handleLogout = () => {
    localStorage.removeItem("refresh");
    window.location.href = "/login";
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [placement] = React.useState("left");

  return (
    <>
      <Button colorScheme="navy" onClick={onOpen} variant="outline">
        <span style={{ color: "black" }}>
          <FaBars />
        </span>
      </Button>
      <Drawer placement={placement} onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">
            <div className="drawer-logo-inner">
              <h1>ZENITHCORP</h1>
            </div>
          </DrawerHeader>
          <DrawerBody style={{ backgroundColor: "navy" }}>
            <ProSidebarProvider>
              <div className="drawer-parent">
                <Sidebar
                  className="drawer-inner"
                  rootStyles={{
                    [`.${sidebarClasses.container}`]: {
                      backgroundColor: "transparent",
                      height: "100%",
                    },
                  }}
                >
                  <Menu
                    className="menu-items-main"
                    menuItemStyles={{
                      button: ({ level, active, disabled, hover }) => {
                        // only apply styles on first level elements of the tree
                        if (level === 0)
                          return {
                            color: disabled ? "#f5d9ff" : "white",
                            backgroundColor: active ? "red" : undefined,
                          };
                      },
                    }}
                    rootStyles={{
                      [`.${menuClasses.button}`]: {
                        "&:hover": {
                          color: "black",
                        },
                        "&:focus": {
                          backgroundColor: "black",
                          color: "white",
                        },
                      },
                    }}
                  >
                    <MenuItem icon={""} component={<Link to="/dashboard" />}>
                      Dashboard
                    </MenuItem>
                    <MenuItem icon={""} component={<Link to="transaction" />}>
                      Transaction
                    </MenuItem>
                    <SubMenu label="Bill Payments">
                      <MenuItem icon={""} component={<Link to="airtime" />}>
                        Airtime
                      </MenuItem>
                      <MenuItem icon={""} component={<Link to="data" />}>
                        Data
                      </MenuItem>
                      <MenuItem icon={""} component={<Link to="electricity" />}>
                        Electricity <sup>Coming Soon</sup>
                      </MenuItem>
                      <MenuItem icon={""} component={<Link to="cable" />}>
                        Cable/TV <sup>Coming Soon</sup>
                      </MenuItem>
                    </SubMenu>

                    <SubMenu label="NYSC">
                      <MenuItem icon={""}>
                        {" "}
                        Consultation <sup>Coming Soon</sup>
                      </MenuItem>
                    </SubMenu>

                    <SubMenu label="Educational Cards/Pin">
                      <MenuItem icon={""}>
                        {" "}
                        Jamb <sup>Coming Soon</sup>
                      </MenuItem>
                      <MenuItem icon={""}>
                        {" "}
                        Result Checker <sup>Coming Soon</sup>{" "}
                      </MenuItem>
                    </SubMenu>

                    <SubMenu label="Other Services">
                      <MenuItem icon={""}>
                        {" "}
                        Web Development <sup>Coming Soon</sup>
                      </MenuItem>
                      <MenuItem icon={""}> Consultation </MenuItem>
                      <MenuItem icon={""}>
                        {" "}
                        Graphic Design <sup>Coming Soon</sup>{" "}
                      </MenuItem>
                    </SubMenu>
                  </Menu>
                </Sidebar>

                <div className="logout-btn">
                  <button onClick={handleLogout}>Log Out</button>
                </div>
              </div>
            </ProSidebarProvider>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
