import { Routes, Route } from "react-router-dom";
import Airtime from "../Pages/Dashboard/Airtime/airtime";
import Cable from "../Pages/Dashboard/cable/cable";
import Dashboard from "../Pages/Dashboard/dashboard/dashboard";
import Data from "../Pages/Dashboard/Data/data";
import Electricity from "../Pages/Dashboard/Electricity/elec";
import DashHome from "../Pages/Dashboard/home";


import Landing from "../Pages/Home/landing";
import Login from "../Pages/Login/login";
import Signup from "../Pages/Signup/signup";
import PersistLogin from "../utils/persistentLogin";
import RequireAuth from "../utils/RequireAuth";
import AllCourse from "../Pages/All-courses/allCourse";
import ContactUs from "../Pages/ContactUs/contactus";
import AboutUs from "../Pages/About-Us/aboutUs";
import FundAccount from "../Pages/Dashboard/FundAcccount/fundAccount";
import NotFound from "../Pages/404/404";
import Consultation from "../Pages/Dashboard/consultation/consultation";
import Transaction from "../components/Transactions/transaction";
import Profile from "../Pages/Dashboard/Profile/profile";



function Router() {
    return (
      <div className="Router">
      <Routes>
          <Route path="/" element={<Landing/>} />
          <Route path="/signup" element={<Signup/>} />
          <Route path="/login" element={<Login/>} />
          <Route path="/allCourse" element={<AllCourse/>} />
          <Route path="/contact-us" element={<ContactUs/>} />
          <Route path="/aboutUs" element={<AboutUs/>} />
          
          <Route element={<PersistLogin/>}>
           <Route element={<RequireAuth/>}>
            <Route path="/dashboard" element={<Dashboard/>} >
            <Route path="/dashboard" element={<DashHome />} />
            <Route path="airtime" element={<Airtime />} />
            <Route path="data" element={<Data />} />
            <Route path="electricity" element={<Electricity />} />
            <Route path="cable" element={<Cable />} />
            <Route path="fundAccount" element={<FundAccount />} />
            <Route path="consultation" element={<Consultation />} />
            <Route path="transaction" element={<Transaction />} />
            <Route path="profile" element={<Profile />} />
            </Route>
            </Route>
            </Route>
           
            
           
            <Route path='*' element={<NotFound />}/>
         
      </Routes>
      </div>
    );
  }
  
  export default Router;