import { useEffect, useState } from "react";
import "./Dashboardheader.scss";
//import SearchIcon from '@mui/icons-material/Search';
//import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
//import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
//import ProfilePic from "../../../../Assets/Image/user.png";
import PlacementExample from "../Drawer/drawer";
//import Avatar from '@mui/material/Avatar';

import { Avatar } from "@chakra-ui/react";

import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  Button,
  PopoverCloseButton,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { handleLogout } from "../side bar/sideBar";

const DashBoardHeader = () => {
  const [userName, setUserName] = useState("");

  useEffect(() => {
    const userNameDash = localStorage.getItem("username");
    setUserName(userNameDash);
    console.log(userNameDash);
  }, [setUserName]);

  return (
    <>
      <div className="header-nav">
        <div className="header-nav-inner">
          <span className="drawer-container">
            <PlacementExample />
          </span>

          <div className="header-nav-inner-main">
            <div></div>

            <div className="Profile-section">
              <span className="noti-icon">
                {/**<NotificationsNoneIcon />*/}
              </span>

              <div className="profile-picture">
                <div
                  style={{
                    position: "relative",
                    zIndex: "7",
                    height: "fit-content",
                  }}
                  className="pop"
                >
                  <Popover>
                    <PopoverTrigger>
                      <Avatar name={userName} src="" className="popover" />
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverHeader>Account</PopoverHeader>
                      <PopoverBody className="pop-body-main">
                        <div className="pop-body">
                          <span>
                            <Link to="profile">Profile</Link>
                          </span>
                          <hr/>
                          <span>
                            <Button onClick={handleLogout}>Logout</Button>
                          </span>
                        </div>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </div>

                <span className="admin-name">Welcome {userName}</span>
                <span className="drop-icon">
                  {/**<ArrowDropDownOutlinedIcon />*/}
                </span>
              </div>
            </div>

            <div></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashBoardHeader;
