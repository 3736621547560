import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import useRefreshToken from "../hooks/useRefreshToken";
import useAuth from "../hooks/useAuth";
import ClipLoader from "react-spinners/ClipLoader";

const PersistLogin = () => {
  const [isLoading, setIsLoading] = useState(true);
  const refresh = useRefreshToken();
  const { auth } = useAuth();

  useEffect(() => {
    const verifyRefreshToken = async () => {
      try {
        await refresh();
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    // persist added here AFTER tutorial video
    // Avoids unwanted call to verifyRefreshToken
    !auth?.accessToken ? verifyRefreshToken() : setIsLoading(false);
  }, [auth?.accessToken, refresh]);

  /** 
     useEffect(() => {
       // console.log(`isLoading: ${isLoading}`)
       // console.log(`aT: ${JSON.stringify(auth?.accessToken)}`)
    }, [isLoading, auth?.accessToken])
*/

  return (
    <>
      {isLoading ? (
        <div
          style={{
            height: "100svh",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <ClipLoader
            color={"#000000"}
            loading={isLoading}
            cssOverride={{ border: "15px solid" }}
            size={150}
            aria-label="Loading Spinner"
          />
        </div>
      ) : (
        <Outlet />
      )}
    </>
  );
};

export default PersistLogin;
