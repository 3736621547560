
//import User from "../users/index"
import "./dashboard.scss"

import DashboardLayout from "../../../Layouts/dashboardLayout"
//import User from "../home"
import { Outlet } from "react-router-dom"
//import {  Route } from "react-router-dom";


const Dashboard = () => {

    return (
      
        <DashboardLayout className="Layout">
        <Outlet/>
          
        </DashboardLayout>
      
    )

}

export default Dashboard