import axios from '../api/axios';
import useAuth from './useAuth';

import Cookies from 'js-cookie';

const useRefreshToken = () => {
    const { setAuth } = useAuth();
    const refreshToken = Cookies.get('refresh');

    const refresh = async () => {
        const response = await axios.post('api/auth/token_refresh/', 
        JSON.stringify({
            refresh: refreshToken
           }),
         {
             headers: { 'Content-Type': 'application/json' },
             
         }
        );
        setAuth(prev => {
            //console.log(JSON.stringify(prev));
            //console.log(response.data.access);
            return { ...prev,
                 accessToken: response.data.access }
        });
        return response.data.accessToken;
    }
    return refresh;
};

export default useRefreshToken;