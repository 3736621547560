import { Link } from "react-router-dom";
import "./footer.scss"
import { FaFacebookF, FaTwitter, FaInstagram} from "react-icons/fa";

const Footer = () => {
    return (
        <>
            <footer>
                <div className="footer-inner">
                    <div className="footer-sec-1">
                        <h1>ZENITHCORP</h1>
                      
                        <div className="footer-sec1-socials">
                            <span><FaFacebookF/></span>
                            <span><FaTwitter/></span>
                            <span><FaInstagram/></span>
                        </div>
                    </div>

                    <div className="footer-sec-2">
                        <h2>Contact Us</h2>
                        <ul>
                            <li><a href="/">Lagos, Nigeria.</a></li>
                            <li><a href="/">support@zenithcorp.com.ng</a></li>
                            <li><a href="/">+234 705 530 6984</a></li>
                        </ul>
                    </div>

                    <div className="footer-sec-3">
                    <h2>News & Blog</h2>

                    <ul>
                            <li><a href="/">Big Ideas Of Business Branding Info.</a></li>
                            <li><a href="/">Ui/Ux Ideas Of Business Branding Info.</a></li>
                          
                        </ul>
                    </div>

                    <div className="footer-sec-4">
                    <h2>Services</h2>

                    <ul>
                            <li><Link  to="dashboard">Jamb E-Pin</Link></li>
                            <li><Link  to="dashboard">Jamb Result Checker.</Link></li>
                            <li><Link to="dashboard">Buy Airtime/Data</Link></li>
                            <li><Link  to="dashboard">Recharge Cable/TV.</Link></li>
                            <li><Link  to="dashboard">Pay Electricity Bill</Link></li>
                          
                        </ul>
                    </div>

                   
                </div>

                <div className="copyright">
                       <div className="copyright-inner">
                       COPYRIGHT ZENITHCORP
                       </div>
                    </div>
            </footer>
        </>
    )
}

export default Footer